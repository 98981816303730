import "@css/app.css";
import "@css/formie.css";

// Put Alpine last, so that it has access to other JS modules
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";

Alpine.plugin(collapse);
Alpine.plugin(focus);

window.Alpine = Alpine;
Alpine.start();

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
